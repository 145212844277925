import React, { useEffect, useState } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import { Card, Row, Col, Button, Table, Form } from "react-bootstrap";
import Plot from "react-plotly.js";
import axios from "axios";
import Stats from "../storeStats/Stats";

function VendorDashboard() {
  const [activeView, setActiveView] = useState("dashboard");
  const [dashboardData, setDashboardData] = useState({});
  const [liveRate, setLiveRate] = useState("");
  const [userDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails")).data
  );
  const getDashboardData = async () => {
    const data = await axios
      .get(`https://zelt-product.moshimoshi.cloud/product/dashboard`, {
        headers: { "x-access-Token": localStorage.getItem("accessToken") },
      })
      .catch((error) => {
        console.log("error ==>", error);
      });
    console.log("getDashboardData", data.data.data);
    localStorage.setItem("vendorData", JSON.stringify(data?.data?.data));
    setDashboardData(data.data.data);
  };

  const liveRateGet = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: "https://zelt-cart.moshimoshi.cloud/cart/live-rate",
      });

      console.log("live rate data", response?.data);
      setLiveRate(response?.data?.data["22kt"]);

      //else {}
    } catch (error) {
      console.log("live rate error ====> ", error);
    }
  };
  const handleDashboardClick = () => {
    setActiveView("dashboard");
  };

  const handleStatsClick = () => {
    setActiveView("statistics");
  };

  useEffect(() => {
    getDashboardData();
    liveRateGet();
  }, []);

  return (
    <div>
      <div class="sidebar">
        <SideBar />
      </div>
      <div class="content">
     
        <div className="container">
          <div className="d-flex mt-4 justify-content-between">
            <div>
            <Button
         variant={activeView === "dashboard" ? "success" : "secondary"}
         onClick={handleDashboardClick}
         className={`${
           activeView === "dashboard" ? "bg-success" : "bg-secondary"
         } text-White mt-2`}>
          Dashboard
        </Button>{" "}
        <Button   variant={activeView === "statistics" ? "bg-success" : "primary"}
  onClick={handleStatsClick}
  className={`${
    activeView === "statistics" ? "bg-success" : "bg-secondary"
  } text-white mt-2`}
          >
Statistics
        </Button>
            </div>
        
        <div className="">
      <FirstNavbar />
        </div>
          </div>
     
        {/* Create buttons to switch between views */}
     
      <>
      {activeView === "dashboard" ? (
        <>
          <h3 className="headertext">Dashboard</h3>
          <div>
            <Card className="p-2">
              <h3 className="headertext">{userDetails.name}</h3>
              <div>
                <h6 className="text">Live Rate</h6>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <p>{liveRate}</p>
                  <Form.Select
                    aria-label="Default select example"
                    size={"sm"}
                    className="selectsizesmall w-25 m-auto"
                    onChange={(e) => {
                      localStorage.setItem("shopId", e.target.value);
                    }}
                    >
                    <option>Select Store</option>
                    {localStorage.getItem("userDetails") &&
                      JSON.parse(
                        localStorage.getItem("userDetails")
                        ).data?.shops?.map((shop) => (
                          <option value={shop._id}>{shop.name}</option>
                          ))}
                  </Form.Select>
                </div>
              </div>
              <hr />
              <Card className="p-2">
                <h3 className="text1">Statistics</h3>
                <Row>
                  <Col md={3} className="">
                    <Card className="p-2 background">
                      <div className="centerAlign">
                        <h3>Jewellery Sold</h3>
                        <div
                          style={{
                            borderRadius: 50,
                            height: 100,
                            width: 100,
                            border: "3px solid #BE783B",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <h1 style={{}}>
                            {dashboardData.productsSold
                              ? dashboardData.productsSold
                              : 0}
                          </h1>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col md={3} className=" ">
                    <Card className="p-2 background">
                      <h3>Schemes Sold</h3>
                      <div
                        style={{
                          borderRadius: 50,
                          height: 100,
                          width: 100,
                          border: "3px solid #BE783B",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                        >
                        <h1 style={{}}>
                          {dashboardData.schemesSold
                            ? dashboardData.schemesSold
                            : 0}
                        </h1>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <hr />
                <h3 className="text1">Customers</h3>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Customer Name</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dashboardData.customers &&
                      dashboardData.customers.map((user, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{user.name}</td>
                          <td>{user.phone}</td>
                          <td>{user.email}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
                          </>
          
      ):(
        <>
          <h3 className="headertext">statistics</h3>
        <Stats/>
        </>
        )}
        </>
    
            </div>
    </div>
      </div>
  );
}

export default VendorDashboard;
