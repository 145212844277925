import React, { useState, useEffect } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
  InputGroup,

} from "react-bootstrap";
import axios from "axios";

const image = require("../../../assets/imagesCustomer/image.png");

function AddBanner() {
  const [jewelleryModal, setJewelleryModal] = useState(false);
  const [coinModal, setCoinModal] = useState(false);
  const [selection, setSelection] = useState("1");

  const handleCloseModal = () => setJewelleryModal(false);
  const handleShowModal = () => setJewelleryModal(true);

  const handleCloseModal1 = () => setCoinModal(false);
  const handleShowModal1 = () => setCoinModal(true);

  const onGoldSelect = (e) => {
    setSelection(e.target.value);
  };

  // const [file, setFile] = useState("");
  const [title, setTitle] = useState("");


  const uploadBanner = async () => {
    //   {
    //     const handleValidationObject = handleValidation();
    //     console.log(handleValidationObject);
    //     if (Object.keys(handleValidationObject).length > 0) {
    //       setInputErrors(handleValidationObject);
    //     } else {
    //       try {
    //         const formData = new FormData();
    //         let token = await getToken();
    //         const store = await getStore();
    //         const headers = {
    //           "x-access-token": token,
    //           "content-type": "multipart/form-data",
    //         };
    //         formData.append("heading", heading);
    //         formData.append("bannerDescription", description);
    //         formData.append("banner_img", {
    //           uri: imagesData,
    //           name: "photo.png",
    //           filename: "imageName.png",
    //           type: "image/png",
    //         });
    //         console.log("ASSETS", store, formData);
    //         const profileData = await profileApiServices
    //           .patchProfile("/shop/" + store, formData, { headers })
    //           .then(async (result) => {
    //             console.log(result.data.data.banner_img, "RESP BANNER");
    //             await getProfile();
    //             return result;
    //           });
    //       } catch (e) {
    //         console.log(e);
    //       }
    //     }
    //   }
  };

  //     {
  //         try {
  //             const formData = new FormData();
  //             let token = await getToken()
  //             const store = await getStore()
  //             const headers = {
  //                 'x-access-token': token,
  //                 'content-type': 'multipart/form-data',
  //             }
  //             formData.append('deleteBannerId', id)
  //             console.log("ASSETS", store, formData)
  //             const profileData = await profileApiServices.patchProfile('/shop/' + store, formData, { headers })
  //                 .then(async (result) => {
  //                     console.log(result.data.data.banner_img, "RESP BANNER")
  //                     await getProfile()
  //                     return result
  //                 }
  //                 )
  //         }
  //         catch (e) {
  //             console.log(e);
  //         }
  //     }

  // const [shopId] = useState(localStorage.getItem("shopId"));
  const [banner, setBanner] = useState([]);

  const [allList, setAllList] = useState([]);

  const getBannerData = async () => {
    try {
      const data = await axios.get(
        `https://zelt-product.moshimoshi.cloud/shop/list/` + localStorage.getItem("shopId")
      );
      console.log("SET LIST---->", data.data.data.banner_img, localStorage.getItem("shopId"))
      setAllList(data.data.data.banner_img);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBannerData();
  },);

  const countByStatus = (status) =>
    allList.reduce((count, item) => {
      if (item.status === status) {
        count++;
      }
      return count;
    }, 0);

  const approvedCount = countByStatus("approved");
  const pendingCount = countByStatus("pending");

  const [file, setFile] = useState();

  const handelOnChange = (e) => {
    setFile(e);

    console.log(e?.target?.files[0], " File Target ==>");
  };

  const handleSubmitBanner = async () => {
    console.log(file, "submit file==>");
    const formData = new FormData();
    formData.append("banner_img", file);
    console.log(formData, "formData=>");
    const data = await axios.patch(
      `https://zelt-product.moshimoshi.cloud/shop/`+localStorage.getItem("shopId"),
      formData,
      {
        headers: { "x-access-Token": localStorage.getItem("accessToken") },
      }
    );

    console.log(data.data);
  };

  return (
    <div>
      <div class="sidebar">
        <SideBar />
      </div>
      <div class="content">
        <div className="container">
          <FirstNavbar />
          <h3 className="headertext">Banner :</h3>
          <InputGroup className="w-25">
            <InputGroup.Text>Select Shop</InputGroup.Text>
            <Form.Select
              aria-label="Select Shops"
              size={"sm"}
              onChange={(e) => {
                localStorage.setItem("shopId", e.target.value)
                getBannerData()
              }}

            >
              {/* <option >Select Store</option> */}
              {
                localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails')).data?.shops?.map(shop => (
                  <option value={shop._id}>{shop.name}</option>
                ))
              }
            </Form.Select>
          </InputGroup>
          <div>
            <Card className="p-2">
              <Row>
                <Col md={4}>
                  <h3 className="headertext">Manage Banner:</h3>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Button variant="warning" onClick={handleShowModal}>
                    Upload new Banner:
                  </Button>
                </Col>
              </Row>
              <hr />
              <Card className="p-2">

                <div className="Bannercontent-flex">
                  <h3 className="text1">Approved Banner:</h3>

                  <p>
                    {" "}
                    <b> Total Count :- {approvedCount} </b>{" "}
                  </p>
                </div>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Status</th>
                      <th>Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allList
                      ? allList
                        ?.filter((ele) => ele.status == "approved")
                        .map((e, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{e?.status}</td>
                            <td>
                              <img src={e?.img_url} className="w-25" />
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </Table>
              </Card>
              <hr />
              <Card className="p-2">
                <div className="Bannercontent-flex">
                  <h3 className="text1">Pending Banner:</h3>
                  <p>
                    {" "}
                    <b> Total Count :- {pendingCount} </b>{" "}
                  </p>
                </div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Status</th>
                      <th> Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allList
                      ? allList
                        ?.filter((ele) => ele.status == "pending")
                        .map((e, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{e?.status}</td>
                            <td>
                              <img src={e?.img_url} className="w-25" />
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </Table>
              </Card>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={jewelleryModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Upload Banner Image:</h4>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="w-50">

                <Form.Label>Banner Image</Form.Label>
                <Form.Control
                  name="file"
                  type="file"
                  className="mb-3"
                  onChange={(e) => handelOnChange(e.target.files[0])}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} variant="secondary">
            Cancel
          </Button>
          <Button variant="warning" onClick={handleSubmitBanner}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={coinModal}
        onHide={handleCloseModal1}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <h4 className="headertext text-center">Banner :</h4>
        <Modal.Body>
          <Row>
            <Col>
              <p className="headertext1">Heading:</p>
              <p className="headertext">Heading</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="headertext1">Description:</p>
              <p className="headertext">Description</p>
            </Col>
          </Row>
          <Row>
            <Figure>
              <Figure.Image width={100} height={80} src={image} />
            </Figure>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal1} variant="secondary">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddBanner;
