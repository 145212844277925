import React, { useEffect, useState } from 'react'
import {BsSpeedometer} from 'react-icons/bs'
import {CgProfile} from 'react-icons/cg'
import {IoStorefrontOutline,IoDiamondOutline,IoStatsChart} from 'react-icons/io5'
import {MdOutlineAdminPanelSettings,MdOutlineSchema} from 'react-icons/md'
import {GiBookmarklet,GiVerticalBanner} from 'react-icons/gi'
import {RiAdvertisementLine} from 'react-icons/ri'
import {
  Accordion,
  Button,
  Card,
  Container,
  Form,
  FormControl,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  NavLink,
  Offcanvas,
} from "react-bootstrap";
import { Navigate,Navigation, useNavigate } from "react-router-dom";
import {
  AiFillAppstore,
  AiFillDatabase,
  AiOutlineRise,
  AiFillSignal,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import './index.css'
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import baseURL from "../../Services/Url";
import { AgGridReact } from "ag-grid-react";
import logozelt from '../../assets/imagesCustomer/logozelt.png'



function SideBar({ Navigation }) {
  const [isOpen, setIsOpen] = useState(false)
    const [counter, setCounter] = useState(0)
  const handleClick =(e)=>{

    // console.log(e);
    Navigation.navigate(`/list_out`,{
      data:e
    })
  }
  
 

const VendorScreens = [{
  path: '/vendorDashboard',
  name: 'Dashboard',
  icon: <BsSpeedometer className="icons"/>
},
{
  path: '/vendorProfile',
  name: 'Profile',
  icon : <CgProfile className='icons'/>
},
{
  path: '/vendorStore',
  name: 'My Store',
  icon : <IoStorefrontOutline className='icons'/>
},
{
  path: '/vendorAdmin',
  name: 'Manage Admin',
  icon : <MdOutlineAdminPanelSettings className='icons'/>
},
{
  path: '/vendorProducts',
  name: 'Physical Jewellery',
  icon : <IoDiamondOutline className='icons'/>
},
{
  path: '/vendorScheme',
  name: ' Scheme',
  icon : <MdOutlineSchema className='icons'/>
},
// {
//   path: '/vendorStats',
//   name: 'Stats',
//   icon : <IoStatsChart className='icons'/>
// },
{
  path: '/vendorBrochure',
  name: 'Brochure',
  icon : <GiBookmarklet className='icons'/>
},
{
  path: '/AddBanner',
  name: 'Add Banner',
  icon : <GiVerticalBanner className='icons'/>
},
{
  path: '/AddAdvertisement',
  name: 'Promote Product',
  icon : <RiAdvertisementLine className='icons'/>
},
]

  return (
    <div>          
      <div className="d-inline-flex px-2 pt-2 gap-2">
        <img src={logozelt} width="25%" height="20%" className="m-0" alt='logo'/>
        <h1>Zelt</h1>
      </div>
       <div className='p-2'>
       
        {/* {userType=== '' && //userType=== 'Vendor' */}
          <>
          {VendorScreens.map((nav, i) => {
            return (
              <p key={i} className=" cursor fs-6 pt-2 pb-1 px-2 m-0">
                
                <Link to={nav?.path} className="p-1">
                {nav?.icon} {nav?.name} 
                </Link>
              </p>
            )
          })}
          </>
        {/* } */}
      </div>
    </div>
  );
}
// }

export default SideBar;
