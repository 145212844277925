import React, { useEffect, useState } from 'react'
import SideBar from '../../dashboard/SideBar'
import FirstNavbar from '../../dashboard/FirstNavbar'
import { Card, Row, Col, Figure, Table, Button, Modal, Form } from 'react-bootstrap'
import Plot from 'react-plotly.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import axios from 'axios'


const image = require('../../../assets/imagesCustomer/image.png');

function JewellerySold() {
    const [jewelleryModal, setJewelleryModal] = useState(false)
    const [coinModal, setCoinModal] = useState(false)
    const [selection, setSelection] = useState('1')
    const [stats, setStats] = useState('');
    const [shopId] = useState(localStorage.getItem('shopId'));

    const navigate = useNavigate();

    const onGoldSelect = (e) => {
        setSelection(e.target.value)
    }

    useEffect(() => {
        const getStats = async () => {
            const data = await axios.get(`https://zelt-product.moshimoshi.cloud/product/stats?shopId=${shopId}`, {
                headers: { "x-access-Token": localStorage.getItem("accessToken") }
            }).catch((error) => {
                console.log('error ==>', error);
            })
            console.log("getShopStats ===>", data.data.data[0].products)
            setStats(data.data.data[0].products)
        }
        getStats();
    }, [shopId])

    return (
        <div>
            <div class="sidebar">
                <SideBar />
            </div>
            <div class="content">
                <div className="container">
                    <FirstNavbar />
                    <h3 className='headertext'>Jewellery Sold:</h3>
                    <div>
                        <Card className='p-2'>
                            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                {
                                    stats && stats.map((p, i) => (
                                        <div onClick={() => navigate('/Jewellery',{data:stats})} style={{}} key={i}>
                                            {p?.title}
                                            <Figure>
                                                <Figure.Image
                                                    width={200}
                                                    height={250}
                                                    src={p?.image[0]}
                                                />
                                            </Figure>

                                        </div>
                                    ))
                                }
                            </div>
                        </Card>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default JewellerySold