import React, { useEffect, useState } from "react";
import SideBar from "../../dashboard/SideBar";
import FirstNavbar from "../../dashboard/FirstNavbar";
import {
  Card,
  Row,
  Col,
  Figure,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import Plot from "react-plotly.js";
import axios from "axios";
import { toast } from "react-toastify";
import { TabGuardComp } from "ag-grid-community";

// const image = require('../../../assets/imagesCustomer/image.png');

function VendorProfile() {
  const [profileModal, setProfileModal] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    bankDetails: {
      bankName: "",
      accNumber: "",
      ifsc: "",
    },
    email: "",
    phone: "",
    image: "",
  });

  const [editUserDetails, setEditUserDetails] = useState({
    name: "",
    bankDetails: {
      bankName: "",
      accNumber: "",
      ifsc: "",
    },
    email: "",
    phone: "",
  });

  const getUserDetails = async () => {
    try {
      const response = await axios.get("https://zelt-auth.moshimoshi.cloud/user", {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("accessToken"),
        },
      });
      if (response.status === 200) {
        setUserDetails(response.data.data);
        setEditUserDetails(response.data.data);

        // console.log(response.data.data, "hello");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  // setEditUserDetails(userDetails);

  const handleCloseModal = () => setProfileModal(false);
  const handleShowModal = () => setProfileModal(true);

  const [editUserDetailsErrors, setEditUserDetailsErrors] = useState({
    name: "",
    bankDetails: { bankName: "" },
    email: "",
    phone: "",
    accNumber: "",
    ifsc: "",
  });
  // setEditUserDetails(userDetails);
  const [image, setImage] = useState(userDetails.image);

  const [imageErrors, setTmageErrors] = useState("");

  useEffect(() => {
    if (image !== "") {
      setTmageErrors("");
    }
  }, [image]);

  const handleChange = (e) => {
    console.log("eeeeeeeeeeeeeeee", e);
    setEditUserDetails({
      ...editUserDetails,
      [e.target.name]: e.target.value,
      [e.target.bankName]: e.target.value,
      [e.target.email]: e.target.value,
      [e.target.phone]: e.target.phone,
      [e.target.accNumber]: e.target.accNumber,
      [e.target.ifsc]: e.target.ifsc,
    });

    setEditUserDetailsErrors({
      ...editUserDetailsErrors,
      [e.target.name]: null,
      [e.target.bankName]: null,
      [e.target.email]: null,
      [e.target.phone]: null,
      [e.target.accNumber]: null,
      [e.target.ifsc]: null,
    });
  };

  const venderValidation = () => {
    const newErrors = {};
    const { name, email } = editUserDetails;
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const nameRegex = /^[a-zA-Z\s]{1,}[\.]{0,1}[a-zA-Z\s]{0,}$/;
    if (name === "" || nameRegex.test(nameRegex)) {
      newErrors.name = "please enter name";
    }
    if (email === "" || !emailRegex.test(email)) {
      newErrors.email = "please enter a valid email address";
    }
    return newErrors;
  };

  const editUser = async () => {
    const venderValidationObject = venderValidation();
    if (Object.keys(venderValidationObject).length > 0) {
      console.log("if working");
      setEditUserDetailsErrors(venderValidationObject);
    } else {
      console.log(editUserDetails, "editUserDetails");
      console.log("else is working");
      const data = await axios
        .put(
          `https://zelt-auth.moshimoshi.cloud/user`,

          editUserDetails,

          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": localStorage.getItem("accessToken"),
            },
          }
        )
        .catch((error) => {
          console.log("error ==>", error);
        });
      console.log("profile updated ", data);

      if (data.status === 200) {
        toast.success(" Profile Details Updated", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: "light",
        });
        handleCloseModal();

        getUserDetails();
      } else {
        console.log("else is working");
      }
    }
  };

  const editProfileImage = async () => {
    const formData = new FormData();
    formData.append("image", image);
    const headers = {
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
      },
    };
    const url = "https://zelt-auth.moshimoshi.cloud/user/profile-img";
    const data = await axios.put(url, formData, headers).catch((error) => {
      console.log("error ==>", error);
      if (error) {
        toast.error("Profile not Updated");
      }
    });
    setEditUserDetails({ ...editUserDetails, image: data.data.data.image });
    if (data.status === 200) {
      toast.success("Profile Picture Updated", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "light",
      });
    }
    console.log("profile updated 200 ", data.data.data.image);
  };

  // console.log(editUserDetailsErrors, "editUserDetailsErrors");

  const handleSaveChange = () => {
    editUser();
  };

  return (
    <>
      <div>
        <div class="sidebar">
          <SideBar />
        </div>
        <div class="content">
          <div className="container">
            <FirstNavbar />
            <h3 className="headertext">Profile</h3>
            {userDetails.phone === "" ? (
              "...loading"
            ) : (
              <div>
                <Card className="p-2">
                  <Row>
                    <Col md={3}>
                      <h3 className="headertext">Vendor Profile</h3>
                    </Col>
                    <Col md={3}>
                      <Button variant="warning" onClick={handleShowModal}>
                        Edit Profile
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Figure>
                      <Figure.Image
                        width={100}
                        height={100}
                        alt="171x180"
                        src={userDetails.image}
                      />
                    </Figure>
                  </Row>
                  <h4 className="headertext">Personal Deatils</h4>
                  <Row>
                    <Col md={3}>
                      <h5>Name:</h5>
                    </Col>
                    <Col md={3}>
                      <h5>{userDetails.name}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <h5>Phone Number:</h5>
                    </Col>
                    <Col md={3}>
                      <h5>+91 {userDetails.phone}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <h5>E-mail Id :</h5>
                    </Col>
                    <Col md={3}>
                      <h5>{userDetails.email}</h5>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md={3}>
                      <h5>Gender :</h5>
                    </Col>
                    <Col md={3}>
                      <h5>{userDetails.gender}</h5>
                    </Col>
                  </Row> */}

                  <Card className="p-2">
                    <h3 className="text1">Available stores</h3>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Store Name</th>
                          <th>Location</th>
                          {/* <th>Phone Number</th>
                      <th>Email</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {userDetails.shops &&
                          userDetails.shops.map((shop, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{shop.name}</td>
                              <td>{shop.location}</td>
                              {/* <td>9878976098</td>
                          <td>manu@gmail.com</td> */}
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Card>
                </Card>
              </div>
            )}
          </div>
        </div>
        {/* {console.log(editUserDetails.name)} */}
        <Modal
          show={profileModal}
          onHide={handleCloseModal}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <h4 className="headertext text-center">Edit Profile Details:</h4>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter Your Name"
                    size="sm"
                    name="name"
                    value={editUserDetails?.name}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                    pattern="/^[a-zA-Z]*$/"
                    required
                  />
                  <span className="text-danger">
                    {editUserDetailsErrors?.name}
                  </span>
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                {console.log(editUserDetails.bankDetails.bankName, "hello")}
                <Form.Group className="mb-3">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter Bank Name"
                    size="sm"
                    name="bankName"
                    value={editUserDetails?.bankDetails.bankName}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {editUserDetailsErrors?.bankName}
                  </span>
                </Form.Group>
              </Col> */}
            </Row>
            <Row>
              {/* <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter Phone Number"
                    size="sm"
                    name="phone"
                    value={editUserDetails?.phone}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {editUserDetailsErrors?.phone}
                  </span>
                </Form.Group>
              </Col> */}
              {/* <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter Account Number"
                    size="sm"
                    name="accNumber"
                    value={editUserDetails?.bankDetails.accNumber}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {editUserDetailsErrors?.accNumber}
                  </span>
                </Form.Group>
              </Col> */}
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email Id</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter Email Id"
                    size="sm"
                    name="email"
                    value={editUserDetails?.email}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {editUserDetailsErrors?.email}
                  </span>
                </Form.Group>
                {/* {console.log(editUserDetails)} */}
              </Col>
              {/* <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Ifsc Code</Form.Label>
                  <Form.Control
                    maxLength={50}
                    type="text"
                    placeholder="Enter IFSC Code"
                    size="sm"
                    name="ifsc"
                    value={editUserDetails?.bankDetails.ifsc}
                    onChange={handleChange}
                    autoComplete="off"
                    className="mb-3"
                  />
                  <span className="text-danger">
                    {editUserDetailsErrors?.ifsc}
                  </span>
                </Form.Group>
              </Col> */}
            </Row>
            <Row>
              <Row>
                <Figure>
                  <Figure.Image
                    width={100}
                    height={100}
                    alt="171x180"
                    src={editUserDetails.image}
                    // onChange={}
                  />
                </Figure>
              </Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Select Profile Image</Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Profile Image"
                    // value={image}
                    className="w-50"
                    pattern="^.+\.(gif|jpe?g|png)$"
                    name="image"
                    onChange={(e) => setImage(e.target.files[[0]])}
                    autoComplete="off"
                  />
                  <span className="text-danger">{imageErrors}</span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Button variant="warning" onClick={editProfileImage}>
                  Update
                </Button>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseModal} variant="secondary">
              Cancel
            </Button>
            <Button variant="warning" onClick={handleSaveChange}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default VendorProfile;
